import { Player } from "@lottiefiles/react-lottie-player";
import { Container, Col } from "react-bootstrap";
import happycoding from "../assets/happycoding.json";
import "../styles/about.scss";

interface IProps {
  id: string;
}

export default function About({ id }: IProps) {
  return (
    <section className="section" id={id}>
      <Container className="row mx-auto">
        <Col id="about-text" className="justify-center my-auto text-left">
          <h5>
            <b>Hello!</b>
          </h5>
          <h1>I am Matson.</h1>
          <h4>I'm a MERN stack developer.</h4>
        </Col>
        <Col className="flex-end my-auto">
          <Player
            autoplay
            loop
            src={happycoding}
            style={{ minWidth: "700px" }}
          />
        </Col>
      </Container>
    </section>
  );
}
