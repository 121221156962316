import React from "react";
import Appbar from "./components/Appbar";
import About from "./components/About";
import Project from "./components/Project";
// import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <>
      <div className="App">
        <Appbar />
        <About id="about" />
        <Project id="project" />
        {/* <Footer id="contact" /> */}
      </div>
    </>
  );
}

export default App;
