import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";
import SNS from "./Socials";
import "../styles/appbar.scss";

export default function NavBar() {
  return (
    <Navbar
      bg="transparent"
      variant="dark"
      className="mx-5 py-4 justify-content-between text-left"
      sticky="top"
      id="appbar"
      expand="md"
      collapseOnSelect
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav activeKey="/">
          <Nav.Item className="mx-2">
            <Nav.Link
              as={Link}
              activeClass="active"
              smooth={true}
              spy={true}
              offset={-100}
              duration={500}
              to="about"
            >
              <h5>About</h5>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mx-2">
            <Nav.Link
              as={Link}
              activeClass="active"
              smooth={true}
              spy={true}
              offset={-100}
              duration={500}
              to="project"
            >
              <h5>Project</h5>
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item className="mx-5">
          <Nav.Link
            as={Link}
            activeClass="active"
            smooth={true}
            spy={true}
            offset={-100}
            duration={500}
            to="contact"
          >
            <h5>Contact</h5>
          </Nav.Link>
        </Nav.Item> */}{" "}
        </Nav>
      </Navbar.Collapse>
      <Nav className="ml-auto">
        <SNS />
      </Nav>
    </Navbar>
  );
}
