import { Container, Row, CardColumns, Card } from "react-bootstrap";
import weather from "../assets/weather.jpg";
import pictionary from "../assets/pictionary.png";
import "../styles/project.scss";

interface IProps {
  id: string;
}

export default function Project({ id }: IProps) {
  return (
    <section className="section" id={id}>
      <Container>
        <h1 className="section-header">My Work</h1>
        <CardColumns>
          <Card className="project">
            <Card.Body>
              <Card.Title>City Weather</Card.Title>
              <Card.Text>
                A city weather app using Openweather Map's API with dynamic city
                background
              </Card.Text>
              <Row className="justify-content-around">
                <a href="https://city-weather-iota.vercel.app">Demo</a>
                <br />
                <a href="https://github.com/nostam/m8-d10-weather">
                  Source Code
                </a>
              </Row>
            </Card.Body>
            <Card.Img variant="bottom" src={weather} alt="weather" />
          </Card>
          <Card className="project">
            <Card.Body>
              <Card.Title>Pictionary</Card.Title>
              <Card.Text>
                Multiplayer online party game! Using drawings to guess words
                with your friends.
              </Card.Text>
              <Row className="justify-content-around">
                <a href="https://pictionary-client.vercel.app/">Demo</a>
                <br />
                <a href="https://github.com/nostam/pictionary-client">
                  Source Code
                </a>
              </Row>
            </Card.Body>
            <Card.Img variant="bottom" src={pictionary} alt="weather" />
          </Card>
        </CardColumns>
      </Container>
    </section>
  );
}
