import { FaLinkedin, FaGithub } from "react-icons/fa";

export default function Socials() {
  return (
    <div>
      <a href="https://github.com/nostam" target="_blank" rel="noreferrer">
        <FaGithub className="icon" />
      </a>
      <a
        href="https://linkedin.com/in/matson-"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin className="icon" />
      </a>
    </div>
  );
}
